<template>
  <div class="page sostenibilita">
    <!-- navigazione interna -->
    <div class="innerNav back">
      <router-link :to="$t('url.territorio')" exact="" tag="a" @click.native="scrollToTop">{{ $t('menu.territorio') }}</router-link>                    
    </div>
    <div class="innerNav next">
      <router-link :to="$t('url.homepage')" exact="" tag="a" @click.native="scrollToTop">{{ $t('menu.homepage') }}</router-link>
    </div>
    
    <!-- banner -->
      <div class="banner" :class="'load-'+isLoad + ' video-'+displayVideo">
        <h1 v-html="$t('sostenibilita.title')" class="t-xlarge"></h1>

        <!-- su DESKTOP -->
        <template v-if="!isMobile">
          <img src="../assets/images/header/sostenibilita-header.jpg" @load="caricatoCover" alt="" class="uk-img uk-animation-kenburns">
          <!-- viene compilato dopo aver caricato l'immagine di cover -->
          <video id="video" autoplay="true" preload="auto" muted="true" loop="true" :class="'video-'+displayVideo">
            <source v-if="isLoad" src="../assets/video/sostenibilita.mp4" type="video/mp4" />
          </video>
        </template>
        
        
        <!-- su MOBILE -->
        <template v-else>
          <img src="../assets/images/header/sostenibilita-header-mobile.jpg" @load="caricatoCoverMobile" alt="" class="mobile uk-animation-kenburns">
        </template>


      </div>

    <span class="scroll"></span>

    <div class="contenuti" v-if="isLoad">

      <!-- sottotitolo -->
      <section class="subtitle">
        <div class="row" v-scrollanimation>
          <h2 class="t-large" v-html="$t('sostenibilita.subtitle')"></h2>
        </div>
      </section>




 <!-- gallery -->
 <section class="gallery">
  <div class="row" v-scrollanimation>
    <div class="uk-position-relative" tabindex="-1" uk-slideshow="min-height: 350; animation: fade; autoplay: true; autoplay-interval: 5000; pause-on-hover: false">

      <ul class="uk-slideshow-items">
        <li>
          <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
            <img src="../assets/images/sostenibilita/galleryA-1.jpg" alt="" uk-cover>
          </div>
      </li>
      <li>
        <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
          <img src="../assets/images/sostenibilita/galleryA-2.jpg" alt="" uk-cover>
        </div>
      </li>
      <li>
        <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
          <img src="../assets/images/sostenibilita/galleryB-1.jpg" alt="" uk-cover>
        </div>
    </li>
      </ul>
    </div>
  </div>
</section>





    <!-- titolo a sx e testo a dx -->
    <section class="txt">
      <div class="row uk-grid uk-grid-collapse">
        <div class="title uk-width-1-3@l" v-scrollanimation>
          <h3 class="t-xlarge" v-html="$t('sostenibilita.title1')"></h3>
        </div>
        <div class="txt uk-width-2-3@l" v-scrollanimation>
          <p class="t-medium"  v-html="$t('sostenibilita.txt1')"></p>
        </div>
      </div>
    </section>


    <!-- img singola  -->
    <section class="img">
      <div class="row" v-scrollanimation>
        <div class="uk-overflow-hidden">
          <img src="../assets/images/sostenibilita/galleryB-2.jpg" alt="" uk-scrollspy="cls: uk-animation-kenburns; repeat: true">
        </div>
      </div>
    </section>

       <!-- titolo a sx e testo a dx -->
       <section class="txt">
        <div class="row uk-grid uk-grid-collapse">
          <div class="title uk-width-1-3@l" v-scrollanimation>
            <h3 class="t-xlarge" v-html="$t('sostenibilita.title2')"></h3>
          </div>
          <div class="txt uk-width-2-3@l" v-scrollanimation>
            <p class="t-medium"  v-html="$t('sostenibilita.txt2')"></p>
          </div>
        </div>
      </section>

    </div>

  <!-- accrocchio per metaTitle e meta-description in lingua -->
  <div id="metaTitle">{{$t('sostenibilita.meta-title')}}</div>
  <div id="metaDescription">{{$t('sostenibilita.meta-description')}}</div>

</div>
</template>


<script>

  import { EventBus } from '../event-bus.js';
  
    export default {

      data: function() {
        return {
          //banner
          isLoad: false,
          displayVideo: false,
          //
          isMobile:'',
          mobileW:960,
          metaTitle: '',
          metaDescription:'',
        }
      },
      methods: {
        //richiamata al click dal navigatore interno (freccine destra e sinistra)
        //vedi su app.vue
        scrollToTop() {
          EventBus.$emit('scrollToTop');
        },
        //banner desktop
        caricatoCover() {
          //una volta caricata l'immagine principale
          this.isLoad = true;
          this.$nextTick(function () {
              window.setTimeout(() => {
                  //dopo qualche secondo visualizzo il video e il resto del contenuti
                  this.displayVideo = true;
                  /*
                  if(document.getElementById("video")){
                    document.getElementById("video").play();
                  }*/             
              },2000);
          })
        },
        //banner mobile
        caricatoCoverMobile(){
          this.isLoad = true;
        },
        checkWW() {
          if(window.innerWidth> this.mobileW){
            this.isMobile = false;
          } else {
            this.isMobile = true;
          }
        }
      },
      mounted: function(){        
        window.addEventListener('resize', this.checkWW);
        this.checkWW();
        this.metaTitle = document.querySelector('#metaTitle').innerText;
        this.metaDescription = document.querySelector('#metaDescription').innerText;
        //controllo lingua e risetto
        if(this.$route.name.includes("-en")){
          this.$i18n.locale = "en";
        } 
      },
      metaInfo: function(){
        return{
          title: this.metaTitle,
          meta: [
            {
              name:"description",
              content: this.metaDescriprion,
            }
          ]
        }
      }
    }
  
  </script>
